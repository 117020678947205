import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {HashRouter, Switch, Route} from 'react-router-dom'
import {useDispatch, useMappedState} from 'redux-react-hook';
import axios from 'axios'

import DevApp from './DevApp'
import StudentIndexApp from "./StudentIndexApp";
import TeacherIndexApp from "./TeacherIndexApp";

import ShareApp from "./ShareApp";
import ShareFolderUnitApp from "./ShareFolderUnitApp";

import FullPagePreloader from '../preloader/FullPagePreloader'

import * as accessGroupsActions from '../../redux/actions/AccessGroupsActions'
import * as usersActions from '../../redux/actions/UsersActions'
import * as groupsActions from '../../redux/actions/GroupsActions'
import LoginApp from "./LoginApp";
import GuestInstructionsApp from "./GuestInstructionsApp";
import CoursesApp from "./CoursesApp";
import TeacherGroupsApp from "./TeacherGroupsApp";
import TeacherGroupApp from "./TeacherGroupApp";
import TeacherInstructionsApp from "./TeacherInstructionsApp";
import TestApp from "./TestApp";
import RecoverPasswordApp from "./RecoverPasswordApp";
import OnlineLessonApp from "./OnlineLessonApp";
import StudentGroupApp from "./StudentGroupApp";
import TeacherToolsApp from "./TeacherToolsApp";
import StudentInstructionsApp from "./StudentInstructionsApp";
import TeacherGroupStudentApp from "./TeacherGroupStudentApp";
import StudentHomeworkApp from "./StudentHomeworkApp";
import StudentDiaryApp from "./StudentDiaryApp";
import GroupDiaryPanel from "../diary/panels/GroupDiaryPanel";
import StudentProfileApp from "./StudentProfileApp";
import TeacherProfileApp from "./TeacherProfileApp";
import {printAllEnPhrases} from "../../utils/Translate";

import TeacherCardsApp from "./TeacherCardsApp";
import TeacherQuestionnairesApp from "./TeacherQuestionnairesApp";
import TeacherDialogsApp from "./TeacherDialogsApp";
import TeacherTreadmillsApp from "./TeacherTreadmillsApp";
import TeacherTestsApp from "./TeacherTestsApp";
import TeacherMaterialsApp from "./TeacherMaterialsApp";
import TeacherExercisesApp from "./TeacherExercisesApp";
import TeacherStatsApp from "./TeacherStatsApp";
import StudentVocabularyApp from "./StudentVocabularyApp";
import BasketDaemonBubble from "../basket/BasketDaemonBubble";
import TeacherAnalyticsApp from "./TeacherAnalyticsApp";
import TeacherLegalApp from "./TeacherLegalApp";
import SimpleAnalyticsAPI from "../../api/SimpleAnalyticsAPI";
import AbituUpdateProfileDaemonPanel from "../profile/panels/AbituUpdateProfileDaemonPanel";
import ConsentTool from "../consent/ConsentTool";
import GroupDiaryPanelApp from "../diary/panels/GroupDiaryPanelApp"
import PostsApp from './PostsApp';
import PQApp from "./PQApp";
import TeacherAccessTool from "../consent/TeacherAccessTool";

printAllEnPhrases();

const teacherRoute = (
    <Switch>
        <Route exact path='/dev' component={DevApp}/>

        <Route exact path='/jew' component={PQApp}/>

        <Route exact path='/share/:type/:id' component={ShareApp}/>

        <Route exact path='/share/folder/:folderId/unit/:unitId' component={ShareFolderUnitApp}/>

        <Route exact path='/tools' component={TeacherToolsApp}/>

        <Route exact path='/stats' component={TeacherStatsApp}/>
        <Route exact path='/analytics' component={TeacherAnalyticsApp}/>

        <Route exact path='/exercises' component={TeacherExercisesApp}/>
        <Route exact path='/materials' component={TeacherMaterialsApp}/>
        <Route exact path='/tests' component={TeacherTestsApp}/>
        <Route exact path='/treadmills' component={TeacherTreadmillsApp}/>
        <Route exact path='/dialogs' component={TeacherDialogsApp}/>
        <Route exact path='/questionnaires' component={TeacherQuestionnairesApp}/>
        <Route exact path='/cards' component={TeacherCardsApp}/>


        <Route exact path='/legal' component={TeacherLegalApp}/>
        <Route exact path='/courses' component={CoursesApp}/>
        <Route exact path='/groups' component={TeacherGroupsApp}/>
        <Route exact path='/recover/:key' component={LoginApp}/>

        <Route exact path='/online/:id' component={OnlineLessonApp}/>

        <Route exact path='/groups/:id' component={TeacherGroupApp}/>
        <Route exact path='/groups/:groupId/students/:studentId' component={TeacherGroupStudentApp}/>
        <Route exact path='/groups/:id/:tab' component={TeacherGroupApp}/>

        <Route exact path='/profile' component={TeacherProfileApp}/>

        <Route exact path='/tests/:id' component={TestApp}/>
        <Route exact path='/tests/:id/:userId' component={TestApp}/>

        <Route exact path='/tests/:id/:userId' component={TestApp}/>

        <Route exact path='/test/:id' component={TestApp}/>

        <Route exact path='/profile' component={LoginApp}/>
        <Route exact path='/configs' component={LoginApp}/>
        <Route exact path='/instructions' component={TeacherInstructionsApp} />
        
        <Route exact path='/' component={PostsApp}/>

        <Route component={TeacherIndexApp}/>
    </Switch>
);


const adminRoute = (
    <Switch>

        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/jew' component={PQApp}/>

        <Route exact path='/recover/:key' component={LoginApp}/>
        <Route exact path='/payment_success/:paddle_id' component={LoginApp}/>

        <Route exact path='/profile' component={LoginApp}/>
        <Route exact path='/configs' component={LoginApp}/>

        <Route component={LoginApp}/>

    </Switch>
);


const userRoute = (
    <Switch>
        <Route exact path='/dev' component={DevApp}/>

        <Route exact path='/recover/:key' component={LoginApp}/>
        <Route exact path='/payment_success/:paddle_id' component={LoginApp}/>

        <Route exact path='/profile' component={LoginApp}/>
        <Route exact path='/configs' component={LoginApp}/>

        <Route component={LoginApp}/>
    </Switch>
);

const studentRoute = (
    <Switch>
        <Route exact path='/dev' component={DevApp}/>

        <Route exact path='/legal' component={TeacherLegalApp}/>

        <Route exact path='/share/:type/:id' component={ShareApp}/>
        <Route exact path='/share/folder/:folderId/unit/:unitId' component={ShareFolderUnitApp}/>

        <Route exact path='/recover/:key' component={LoginApp}/>
        <Route exact path='/payment_success/:paddle_id' component={LoginApp}/>

        <Route exact path='/vocabulary' component={StudentVocabularyApp}/>
        <Route exact path='/courses' component={CoursesApp}/>
        <Route exact path='/profile' component={StudentProfileApp}/>
        <Route exact path='/configs' component={LoginApp}/>

        <Route exact path='/homework' component={StudentHomeworkApp}/>
        <Route exact path='/groups' component={TeacherGroupsApp}/>
        <Route exact path='/groups/:id' component={StudentGroupApp}/>
        <Route exact path='/diary' component={StudentDiaryApp}/>
        <Route exact path='/diary/:id' component={GroupDiaryPanelApp}/>

        <Route exact path='/online/:id' component={OnlineLessonApp}/>

        <Route exact path='/tests/:id' component={TestApp}/>
        <Route exact path='/test/:id' component={TestApp}/>
        <Route exact path='/tests/:id/:userId' component={TestApp}/>

        <Route exact path='/instructions' component={StudentInstructionsApp} />
        
        <Route exact path='/' component={PostsApp}/>

        <Route component={StudentIndexApp}/>

    </Switch>
);

const guestRoute = (
    <>
        <Switch>
            <Route exact path='/dev' component={DevApp}/>

            <Route exact path='/instructions' component={GuestInstructionsApp}/>

            <Route exact path='/legal' component={TeacherLegalApp}/>

            <Route exact path='/share/:type/:id' component={ShareApp}/>

            <Route exact path='/share/folder/:folderId/unit/:unitId' component={ShareFolderUnitApp}/>

            <Route exact path='/recover/:key' component={LoginApp}/>
            <Route exact path='/payment_success/:paddle_id' component={LoginApp}/>

            <Route exact path='/profile' component={LoginApp}/>
            <Route exact path='/configs' component={LoginApp}/>

            <Route component={LoginApp}/>
        </Switch>
    </>
);


function RouterApp(props) {
    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser} = useMappedState(mapState);
    const dispatch = useDispatch();

    console.log('RouterApp: initialized, currentUser = ', initialized, currentUser);


    useEffect(() => {
        dispatch(usersActions.initializeAuthorization()).then(pld => {
            if (pld.user != undefined) {
                window.currentUserId = pld.user.id;
                console.log('==>>> pld = ', pld);
                dispatch(groupsActions.loadAllGroups());
                dispatch(accessGroupsActions.loadAccessGroups());
                if (pld?.user?.userRole == 'teacher') {
                    // dispatch(materialsActions.)
                }
                // SimpleAnalyticsAPI.runTracking(pld?.user?.id, pld?.user?.userRole).then(() => {
                //
                // });
                dispatch(usersActions.getCurrentUserInfo());
            }
        }).catch(exc => {
            console.log('!!! => exc = ', exc);
        });
    }, []);

    if (gup('recovery_code') != undefined && gup('recovery_code').length > 10){
        return (
            <RecoverPasswordApp recovery_code={gup('recovery_code')} />
        )
    }

    if (initialized == false) {
        return (
            <FullPagePreloader visible={true}/>
        )
    }

    let route = guestRoute;
    let isGuest = (currentUser == undefined);
    let isAdmin = (isGuest == false && currentUser.userRole == 'admin');
    let isTeacher = (isGuest == false && currentUser.userRole == 'teacher');

    if (isGuest == false) {
        route = isAdmin == true ? adminRoute : studentRoute;
    }
    if (isTeacher == true) {
        route = teacherRoute;
    }

    console.log('current user = ', currentUser);

    return (
        <HashRouter>
            <Wrapper>
                {route}
                <BasketDaemonBubble/>
                <AbituUpdateProfileDaemonPanel/>
                {isTeacher == false ? null :
                    <>
                        <ConsentTool/>
                        <TeacherAccessTool/>
                    </>
                }
            </Wrapper>
        </HashRouter>
    );
}

const Wrapper = styled.div`
  font-family: Montserrat;
  //font-family: "Avenir Next Cyr";
`;

export default (RouterApp)

function gup( name, url ) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? null : results[1];
}